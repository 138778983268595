//import { getAllPostsForHome, getPostAndMorePosts } from '../lib/api'
var existingEnv = ["alphalives", "pwafr","netExpertFr", "websitecreafr"];
var OtherEnv = ["netExpertFr", "websitecreafr"];
var envInfo= {
    "alphalives":{
        "favicon": "alphalives",
        "website": "https://www.alphalives.com/",
        "name": "alphalives",
    },
    "pwafr":{
        "favicon": "pwa",
        "website": "https://www.progressive-web-apps.fr/",
        "name": "progressive-web-apps",
    },
    "netExpertFr":{
        "favicon": "ne",
        "website": "https://www.net-expert.fr/",
        "name": "net-expert",
    },
    "websitecreafr":{
        "favicon": "wsc",
        "website": "https://www.websitecrea.fr/",
        "name": "Agence développement web websitecrea",
    },
}

export function checkEnv(siteEnv, preview = false ){
    //var host = req.get('host');
    //var allPosts = []
    //var articles = []
    //console.log(process.env)
    //var existingHost = [ "https://www.alphalives.com/","https://www.progressive-web-apps.fr/","http://localhost:3000/"]
    if(existingEnv.indexOf(siteEnv) === -1){
        /* if(existingHost.indexOf(host+"/") !== -1){
            //console.log(existingHost.indexOf(host+"/"))
            process.env['CONTENTFUL_SITE_TAG'] = existingEnv[existingHost.indexOf(host+"/")];
            siteEnv = existingEnv[existingHost.indexOf(host+"/")];
            //console.log(process.env['CONTENTFUL_SITE_TAG'])
            allPosts = (await getAllPostsForHome(preview)) ?? []
            articles = (await getPostAndMorePosts('', preview)) ?? []
        } */
        //console.log("Not found Env ! ", existingEnv.indexOf(siteEnv) === -1)
        return false;
    }
    return siteEnv;
    
}


export function getOtherEnv(siteEnv, preview = false){
    if(OtherEnv.indexOf(siteEnv) === -1){
        return false
    }
    return true
}

export function getFavicon(siteEnv){
    if(envInfo && envInfo[siteEnv]){
        return envInfo[siteEnv].favicon
    }
    return false
}
export function websiteUrl(siteEnv){
    if(envInfo && envInfo[siteEnv]){
        return envInfo[siteEnv].website
    }
    return false
}       

export function website(siteEnv){
    if(envInfo && envInfo[siteEnv]){
        return envInfo[siteEnv].name
    }
    return false
}